.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.loading-image {
    width: 80px;
    /* Ancho de la imagen */
    height: 80px;
    /* Altura de la imagen */
    object-fit: cover;
    /* Mantener las proporciones de la imagen */
    filter: grayscale(100%);
    /* Comienza en escala de grises */
    animation: colorize 3s infinite alternate;
}

.loading-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: white;
}

@keyframes colorize {
    from {
        filter: grayscale(100%);
    }

    to {
        filter: grayscale(0%);
    }
}