* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.homeContainer {
    background-color: #F3F7F9;
}

.headerHome {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    place-content: space-between
}

.menuDesplegable button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    padding: 10px;
}

.menuDesplegable {
    position: relative;
    width: 200px;
    margin-right: 5%;
}

.menuDesplegable ul {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 1000;
    width: 100%;
    top: 100%;
    left: 0;
    padding-left: 0;
}

.menuDesplegable li {
    list-style-type: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 610;
    font-size: unset;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
}

.menuDesplegable ul li a {
    color: black;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
}

.menuDesplegable ul li a:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
    width: 100%;
}

.menuDesplegable ul li a.selected {
    background-color: #3A93F7;
    color: #ffffff;
}

.menuDesplegable img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.headerHome h2 {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #131357;
    font-size: xx-large;
    margin-top: 4px;
    margin-left: 1%;
    margin-bottom: 5px;
}

.bodyHome {
    display: flex;
    width: 100%;
    height: 100vh;
}

.menuPrincipal {
    height: 100;
    width: 19%;
    display: flex;
    color: white;
    background-color: #131357;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    padding-top: 1.25%;
    flex-flow: column;
}

.menuPrincipal button {
    cursor: pointer;
    color: white;
    background-color: #131357;
    font-family: 'Montserrat', sans-serif;
    font-size: large;
    display: flex;
    margin-top: 7%;
    border: 0;
    padding-bottom: 0;
    align-items: center;
    height: 45px;
    margin-left: 1%;
}

.menuPrincipal h3 {
    font-weight: 500;
    margin-left: 10%;
    font-size: smaller;
}

.menuPrincipal h2 {
    margin-top: 60%;
    font-weight: 500;
    margin-left: 10%;
    font-size: smaller;
}

.menuPrincipal ul {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #131357;
    border: 0;
    width: 100%;
    place-items: center;
    margin-bottom: 5%;
}

.menuPrincipal li:hover {
    background: #232386;
}

.bodyHome p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: black;
    font-size: 36px;
    margin-top: 3rem;
    margin-left: 100px;
}

.menuPrincipal li {
    list-style-type: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: medium;
    display: block;
    text-wrap: nowrap;
    text-align: justify;
    padding: 5px;
    width: 100%;
    height: 40px;
}

.menuPrincipal ul li a {
    color: white;
    text-decoration: none;
    width: 100%;
    display: block;
    margin-left: 20%;
}

.vouchersMenu {
    margin-top: 12rem;
}

.icon-left {
    margin-right: 0.5rem;
}

.icon-right {
    position: absolute;
    left: 12%;
}

.icon-right-perfil {
    margin-left: 2%;
}

.icon-left-perfil {
    margin-right: 0.5rem;
    margin-left: 15%;
}

@media screen and (max-width: 1900px) {
    .menuPrincipal {
        font-size: xx-large;
    }

    .menuPrincipal ul {
        width: 100%;
    }

}

@media screen and (max-width: 1700px) {
    .menuPrincipal {
        font-size: x-large;
    }

    .menuPrincipal ul {
        width: 100%;
        height: 5%;
    }

}

@media screen and (max-width: 1500px) {
    .menuPrincipal {
        font-size: larger;

    }

    .menuDesplegable img {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 1300px) {
    .menuPrincipal {
        width: 28%;
        font-size: large;
        padding-bottom: 8%;
    }

    .menuPrincipal h2 {
        margin-top: 40%;
    }

    .menuPrincipal button {
        width: 180px;
        font-size: large;
    }

    .menuPrincipal ul {
        width: 100%;
        font-size: large;
    }

    .menuPrincipal li {
        font-size: large;
    }

    .icon-right {
        left: 15%;
    }

}

@media screen and (max-width: 1100px) {
    .menuPrincipal {
        width: 25%;
        font-size: medium;
        padding-bottom: 8%;
    }

    .menuPrincipal button {
        width: 180px;
        font-size: medium;
    }

    .menuPrincipal ul {
        width: 100%;
        font-size: medium;
        height: 4.5%;
    }

    .menuPrincipal li {
        font-size: medium;
    }

    .icon-right {
        left: 15%;
    }

}

@media screen and (max-width: 900px) {
    .menuPrincipal {
        font-size: small;
        padding-bottom: 8%;
    }

    .menuPrincipal button {
        width: 18%;
        font-size: small;
    }

    .menuPrincipal ul {
        height: 4%;
        width: 100%;
        font-size: small;
    }

    .menuPrincipal li {
        font-size: small;
    }

    .icon-right {
        left: 14%;
    }

}

@media screen and (max-width: 800px) {
    .menuPrincipal {
        font-size: smaller;
        padding-bottom: 8%;
    }

    .menuPrincipal button {
        font-size: smaller;
    }

    .menuPrincipal ul {
        font-size: smaller;
        height: 3%;
    }

    .menuPrincipal li {
        font-size: smaller;
    }

    .icon-right {
        left: 14%;
    }
}