.card-proveedores {
    display: flex;
    flex-direction: column;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 75%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding-bottom: 2%;
    padding-top: 4%;
    padding-left: 7%;
    padding-right: 7%;
}

.card-proveedores-div {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card-proveedores a {
    color: #647787;
    margin-bottom: 4.5%;
    font-size: medium;
}

.card-proveedores form {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    font-size: medium;
    display: flex;
    justify-content: space-between;
}

.card-proveedores input {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    font-size: small;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787ed;
    border-radius: 10px;
    opacity: 1;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 2%;
}

.card-proveedores input:hover {
    background-color: #3A93F729;
    border: 0.5px solid #3A93F7;
    border-radius: 10px;
}

.card-proveedores input:focus {
    border-color: #3A93F7;
}

.card-proveedores input::placeholder {
    color: #647787ed;
}

.dropdown-container-regimen {
    height: 92px;
    margin-top: 4.4%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 25%;
}

.dropdown-container-regimen ul {
    list-style-type: none;
}

.dropdown-container-regimen button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}