.roles-table {
    overflow-x: auto;
    white-space: nowrap;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
}

.roles-table table {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 2%;
}

.roles-table th,
.roles-table td {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}

.tabla-header-roles {
    text-align: justify;
    letter-spacing: 0.2px;
    color: gray;
    font-size: small;
    height: 45px;
}

.tabla-header-roles th {
    padding-left: 2%;
}

.category-fila {
    cursor: pointer;
    font-size: small;
    height: 40px;
    color: #FFFFFF;
    background-color: #131357;
}

.category-fila td {
    padding-left: 3%;
}

.permisos-fila {
    text-align: justify;
    font-size: x-small;
    height: 25px;
    color: #647787;
}

.permisos-fila td {
    padding-left: 5%;
}

.separator-tabla td {
    background-color: lightgray;
    height: 0.5px;
}

.button-container-roles {
    text-align: center;
    margin-left: 14vh;
    margin-top: 2%;
    margin-bottom: 1%;
}

.botonSecondary {
    margin-left: 14vh;
    width: 35vh;
    height: 6vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-weight: bold;
    font-size: 18px;
}

.botonPrincipal {
    margin-left: 14vh;
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.filter-users-roles {
    display: flex;
    width: 100%;
    margin-top: -6%;
    margin-bottom: 5%;
    place-content: end;
}

.filter-users-roles button {
    margin-right: -10%;
    cursor: pointer;
    width: 190px;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: medium;

}

.filter-users-roles button:hover {
    background: #4682c7;
}

.botonSecondary-roles {
    width: 250px;
    height: 48px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-weight: bold;
    font-size: 18px;
    margin-left: 155px;
}

.botonPrincipal-roles {
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    margin-left: 155px;
}

@media screen and (max-width: 1300px) {
    .roles-table {
        width: 93%;
    }

    .filter-users-roles button {
        width: 165px;
        height: 40px;
        margin-right: -65px;
    }
}

@media screen and (max-width: 1150px) {
    .botonSecondary {
        margin-left: 55px;
        width: 200px;
        height: 40px;
        font-size: 15px;
    }

    .permisos-fila td {
        padding-left: 4.5%;
    }

    .botonPrincipal-roles {
        margin-left: 55px;
        width: 200px;
        height: 40px;
        font-size: 15px;
    }
}

@media screen and (max-width: 1000px) {

    .tabla-header-roles {
        font-size: x-small;
        height: 30px;
    }

    .category-fila {
        font-size: x-small;
        height: 25px;
    }

    .permisos-fila {
        font-size: xx-small;
        height: 20px;
    }

    .permisos-fila td {
        padding-left: 4%;
    }

    .filter-users-roles button {
        width: 125px;
        height: 30px;
        font-size: x-small;

    }

}

@media screen and (max-width: 800px) {

    .roles-table th,
    .roles-table td {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
    }

    .tabla-header-roles {
        font-size: xx-small;
        height: 25px;
    }

    .category-fila {
        font-size: xx-small;
        height: 20px;
    }

    .permisos-fila {
        font-size: 9px;
        height: 15px;
    }

    .permisos-fila td {
        padding-left: 3%;
    }

    .filter-users-roles button {
        width: 100px;
        height: 25px;
        font-size: xx-small;

    }

    .botonSecondary {
        margin-left: 55px;
        width: 175px;
        height: 33px;
        font-size: 13px;
    }

    .botonPrincipal-roles {
        margin-left: 55px;
        width: 175px;
        height: 33px;
        font-size: 13px;
    }

}