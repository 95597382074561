.titulo-container-solicitud {
    display: flex;
    justify-content: space-between;
    margin-right: 14vh;
    margin-top: 5vh;
}

.titulo-container-solicitud button {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    width: 165px;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: medium;
}

.titulo-container-solicitud button:hover {
    background: #4682c7;
}

.header-solicitud {
    display: flex;
    flex-direction: column;
    margin-left: 14vh;
    color: #647787;
    font-family: 'Montserrat', sans-serif;
}

.header-solicitud span {
    margin-bottom: 3vh;
}

.card-informacion-solicitud {
    margin-left: 14vh;
    margin-right: 14vh;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 2%;
}

.card-informacion-solicitud-span {
    margin-bottom: 3%;
}

.card-informacion-solicitud-proyecto {
    display: flex;
    flex-wrap: wrap;
}

.field-container-proyecto {
    margin-bottom: 2%;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.card-informacion-solicitud-proyecto input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #F3F7F9 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 5px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 5px;
    margin-top: 5px;
    border: none;
}

.card-informacion-solicitud-proyecto button {
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.card-informacion-solicitud-proyecto button:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
}

.card-informacion-solicitud-proyecto button:focus {
    border-color: #3A93F7;
}

.dropdown-container-proyecto {
    overflow-y: scroll;
    height: 20%;
    margin-top: 4.5%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 30%;
}

.dropdown-container-proyecto ul {
    list-style-type: none;
}

.dropdown-container-proyecto button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

.card-informacion-solicitud-cheque {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.card-informacion-solicitud-cheque label {
    margin-bottom: 5px;
}

.card-informacion-solicitud-cheque button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    height: 45px;
    width: 47.5%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}


.card-informacion-solicitud-cheque button:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
}

.card-informacion-solicitud-cheque button:focus {
    border-color: #3A93F7;
}

.dropdown-cheque {
    overflow-y: scroll;
    height: 27%;
    margin-top: 4.5%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 30.5%;
}

.dropdown-cheque ul {
    list-style-type: none;
}

.dropdown-cheque button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

.dropdown-cheque-nuevo-proveedor {
    display: flex;
    align-items: center;
}

.dropdown-cheque-nuevo-proveedor button {
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 20px #00000029;
    border: none;
    border-radius: 10px;
    opacity: 1;
    width: 17%;
    height: 35px;
}

.dropdown-cheque-nuevo-proveedor button:hover {
    background-color: #1f76da;
    border: #FFFFFF;
}

.dropdown-cheque-nuevo-proveedor input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    height: 45px;
    width: 58%;
    border-radius: 4px;
    border: solid 0.5px #647787;
}

.card-informacion-solicitud-description {
    display: flex;
    flex-direction: column;
}

.card-informacion-solicitud-description textarea {
    width: 98%;
    height: 14vh;
    padding: 7px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.card-informacion-solicitud-description textarea:hover {
    border-color: #3A93F7;
}

.card-informacion-solicitud-description textarea:focus {
    border-color: #3A93F7;
}

.card-detalle-solicitud {
    margin-left: 14vh;
    margin-right: 14vh;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;

}

.card-detalle-solicitud-span {
    display: flex;
    justify-content: space-between;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.card-detalle-solicitud-span button {
    cursor: pointer;
    background-color: #FFFFFF;
    width: 165px;
    height: 45px;
    box-shadow: 3px 3px 5px #00000029;
    border: 1px solid #3A93F7;
    border-radius: 10px;
    opacity: 1;
    color: #3A93F7;
}

.card-detalle-solicitud-span button:hover {
    background: #3A93F729 0% 0% no-repeat padding-box;
    border: 1px solid #3A93F7;
    border-radius: 10px;
    opacity: 1;
}

.card-detalle-solicitud-span button:hover {
    background-color: rgb(238, 236, 236);
}

.card-detalle-solicitud-titulos {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 2%;
    width: 100%;
    border-bottom: solid 0.5px #647787;
    text-align: center;
}

.card-detalle-solicitud-titulos span {
    width: 100%;
    display: flex;
    text-align: -webkit-left;
    align-self: center;
    margin-bottom: 15px;
}

.card-detalle-solicitud-detalle {
    font-size: 12px;
    height: 15%;
    display: flex;
    padding-right: 2%;
    padding-left: 1%;
    border-bottom: solid 0.5px #647787;
}

.card-detalle-solicitud-detalle span {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-self: center;
    width: 3%;
    margin-top: 3.5%;
    margin-bottom: 2%;
}

.card-detalle-solicitud-detalle input {
    width: 9%;
    margin-left: 0.5%;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px;
    border-color: #647787;
    display: flex;
    align-self: center;
    margin-bottom: 2%;
    margin-top: 3.5%;
}

.card-detalle-solicitud-total {
    font-family: 'Montserrat', sans-serif;
    padding-left: 4%;
    padding-bottom: 2%;
    padding-top: 2%;
}

.menu-dropdown-detalle-requi {
    margin-top: 5%;
}

.menu-dropdown-detalle {
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    right: 20px;
}

.menu-dropdown-detalle li {
    padding: 8px;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px;
    border-color: #647787;
}

.menu-dropdown-detalle li:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
    width: 100%;
}

.button-container-solicitud {
    text-align: center;
    margin-top: 2%;
    margin-bottom: 3%;
}

.botonSecondary-solicitud {
    letter-spacing: 1px;
    width: 35vh;
    height: 45px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-size: 18px;
}

.botonPrincipal-solicitud {
    letter-spacing: 1px;
    cursor: pointer;
    width: 35vh;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    .button-container-proyecto {
        margin-left: 0;
    }

    .card-detalle-solicitud-titulos {
        font-size: 13.5px;
    }

    .card-detalle-solicitud-detalle {
        font-size: 12px;
    }
}

@media screen and (max-width: 1100px) {
    .titulo-container-solicitud button {
        width: 125px;
    }

    .card-informacion-solicitud {
        font-size: 13px;
    }

    .card-detalle-solicitud-span button {
        height: 35px;
        width: 125px;
        font-size: 13px;
    }

    .card-detalle-solicitud-titulos {
        font-size: 11px;
    }

    .card-detalle-solicitud-detalle {
        font-size: 11px;
    }

    .card-detalle-solicitud-total {
        font-size: 13px;
    }
}

@media screen and (max-width: 890px) {
    .titulo-container-solicitud button {
        width: 100px;
        font-size: x-small;
    }
}

@media screen and (max-width: 740px) {

    .titulo-container-solicitud button {
        height: 30px;
    }
}


.print-mode .ocultar-durante-impresion {
    display: none;
}