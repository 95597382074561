.filter-users-historial {
    padding: 30px;
    display: flex;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 85%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
}

.filter-users-historial form {
    width: 100%;
}

.filter-users-historial a {
    align-self: center;
}

.label-historial {
    display: flex;
    margin-top: 4vh;
    margin-left: 14vh;
    color: #647787;
    font-family: 'Montserrat', sans-serif;
    justify-content: space-between;
    margin-right: 18vh;
}

.label-historial label {
    margin-bottom: 3vh;
}

.historial-icon-container {
    position: relative;
    display: flex;
    align-items: center;
}

.historial-clear-icon {
    position: absolute;
    right: 10px;
    color: #3A93F7;
    cursor: pointer;
}

.form-column input {
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.boton-form-column {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: -webkit-left;
    font-family: 'Montserrat', sans-serif;
    height: 40px;
    color: gray;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;
}

.form-column-fecha {
    position: relative;
}

.fecha-modificada {
    border: 1px solid #3A93F7 !important;
}

.fecha-selector-container {
    z-index: 100;
    display: flex;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    color: #1A1A3B;
    font-weight: 600;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    border: 1px solid #647787;
    opacity: 1;
    height: 130px;
    width: 520px;
    padding: 10px;
    text-align: -webkit-center;
}

.selector-fecha {
    display: flex;
    flex-direction: column;
}

.custom-buttons-date {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-buttons-fechas {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 25px;
}

.custom-buttons-fechas button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: small;
    cursor: pointer;
    background-color: #FFFFFF;
    width: 100px;
    height: 45px;
    box-shadow: 3px 3px 5px #00000029;
    border: 1px solid #3A93F7;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 5px;
    margin-right: 10px;
    color: #3A93F7;
}

.custom-buttons-fechas button:hover {
    background: #3A93F729 0% 0% no-repeat padding-box;
    border: 1px solid #3A93F7;
    border-radius: 10px;
    opacity: 1;
}

.form-column input::placeholder {
    padding-left: 5px;
}

.filter-users-historial .form-row {
    display: flex;
    justify-content: space-between;
}

.filter-users-historial .form-column {
    flex: 1;
    padding: 0 10px;
}

.dropdown-historial {
    overflow-y: scroll;
    height: 20%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 17%;
}

.dropdown-historial ul {
    list-style-type: none;
}

.dropdown-historial button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

.dropdown-historial button:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
}

.dropdown-historial button:focus {
    border-color: #3A93F7;
}

@media screen and (max-width: 1385px) {
    .fecha-selector-container {
        height: 125px;
        width: 425px;
        font-size: 13px;
    }

    .react-datepicker-wrapper {
        font-size: 13px;
        width: 150px;
        height: 50px;
    }

    .custom-buttons-fechas button {
        font-size: 11px;
        width: 80px;
        height: 35px;
    }
}

@media screen and (max-width: 1280px) {
    .fecha-selector-container {
        height: 120px;
        width: 410px;
        font-size: 13px;
    }

    .react-datepicker-wrapper {
        font-size: 13px;
        width: 120px;
        height: 45px;
    }

    .custom-buttons-fechas button {
        font-size: 10px;
        width: 66px;
        height: 35px;
    }

    .form-column input {
        height: 35px;
    }
}

@media screen and (max-width: 1150px) {
    .boton-form-column {
        font-size: 9px;
    }

    .dropdown-historial button {
        font-size: 9px;
    }

    .form-column input {
        font-size: 10px;
    }

}

@media screen and (max-width: 1140px) {
    .fecha-selector-container {
        height: 110px;
        width: 318px;
        font-size: 11px;
    }

    .react-datepicker-wrapper {
        font-size: 13px;
        width: 107px;
        height: 40px;
    }

    .custom-buttons-fechas button {
        font-size: 9px;
        width: 56px;
        height: 35px;
    }

    .form-column input {
        height: 35px;
    }
}

@media screen and (max-width: 1115px) {
    .fecha-selector-container {
        height: 100px;
        width: 290px;
        font-size: 10px;
    }

    .react-datepicker-wrapper {
        font-size: 13px;
        width: 97px;
        height: 35px;
    }

    .custom-buttons-fechas button {
        font-size: 8px;
        width: 50px;
        height: 35px;
    }

    .form-column input {
        height: 30px;
    }
}