.toggle-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toggle-button {
    width: 125px;
    height: 30px;
    border-radius: 15px;
    background-color: #ff5b5b;
    position: relative;
    transition: background-color 0.3s;
}

.toggled .toggle-button {
    background-color: #4caf50;
}

.toggle-indicator {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
    margin-top: 3px;
    margin-left: 1px;
}

.toggled .toggle-indicator {
    transform: translateX(99px);
}

.status-text {
    position: absolute;
    margin-left: 30px;
    font-size: 11px;
    color: #FFFFFF;
}

.toggled .status-text {
    color: #FFFFFF;
}

.boton-form-column.selected-account {
    border: 1px solid #3A93F7;
}