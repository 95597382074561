.personal-details-usuario {
    margin-left: 8%;
    width: 80%;
    font-family: 'Montserrat', sans-serif;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: auto;
    grid-gap: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 3%;
    padding-bottom: 5%;
}

.personal-details-usuario .left-column {
    display: flex;
    flex-direction: column;
}

.personal-details-usuario .right-column {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    place-content: center;
}

.personal-details-usuario .right-column img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin-bottom: 8%;
}

.personal-details-usuario .right-column label {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0px;
    color: #3A93F7;
    opacity: 1;
    font-size: large;
}

.personal-details-usuario img {
    border-radius: 50%;
    width: 80%;
    height: 60%;
}

.personal-details-usuario input {
    margin-top: 1%;
    margin-bottom: 3%;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details-usuario input:focus {
    border-color: #3A93F7;
    outline: none;
}

.personal-details-usuario select:focus {
    border-color: #3A93F7;
    outline: none;
}

.personal-details-usuario select {
    margin-top: 1%;
    margin-bottom: 3%;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details-usuario select option {
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details-usuario input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-size: medium;
}

.personal-details-usuario label {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    margin-top: 2%;
    margin-bottom: 1%;
    padding-left: 7px;
}

.select-container select {
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    padding-right: 30px;
}

.eyeIcon-security {
    font-size: 21px;
    position: absolute;
    top: 45%;
    right: 3%;
    transform: translate(0, -50%);
    color: #3A93F7;
    cursor: pointer;
}

.select-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.select-arrow-nuevo {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-90%);
    pointer-events: none;
}

.select-arrow-editar {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-90%);
    pointer-events: none;
}

.button-container-nuevo-usuario {
    text-align: center;
    margin-top: 2%;
    margin-bottom: 1%;
}

.botonSecondary {
    margin-left: 14vh;
    width: 35vh;
    height: 6vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-weight: bold;
    font-size: 18px;
}

.botonPrincipal {
    margin-left: 14vh;
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}


@media screen and (max-width: 1100px) {
    .personal-details-usuario label {
        font-size: small;
    }

    .personal-details-usuario input::placeholder {
        font-size: small;
    }

    .personal-details-usuario input {
        padding-left: 15px;
        margin-bottom: 2%;
        font-size: small;
        width: 95%;
    }

    .personal-details-usuario select {
        font-size: small;
        width: 95%;
    }

    .personal-details-usuario select option {
        font-size: small;
    }

    .personal-details-usuario .right-column label {
        font-size: small;
    }

    .personal-details-usuario .right-column img {
        width: 185px;
        height: 185px;
    }

    .eyeIcon-security {
        right: 7%;
    }
}

@media screen and (max-width: 950px) {
    .personal-details-usuario label {
        font-size: x-small;
    }

    .personal-details-usuario input::placeholder {
        font-size: x-small;
    }

    .personal-details-usuario input {
        font-size: x-small;
        width: 95%;
        height: 30px;
    }

    .personal-details-usuario select {
        font-size: x-small;
        width: 95%;
        height: 30px;
    }

    .personal-details-usuario select option {
        font-size: x-small;
    }

    .personal-details-usuario .right-column label {
        font-size: x-small;
    }

    .personal-details-usuario .right-column img {
        width: 150px;
        height: 150px;
    }

}

@media screen and (max-width: 850px) {
    .eyeIcon-security {
        font-size: 18px;
    }
}

@media screen and (max-width: 800px) {
    .personal-details-usuario label {
        font-size: xx-small;
    }

    .personal-details-usuario input::placeholder {
        font-size: xx-small;
    }

    .personal-details-usuario input {
        font-size: xx-small;
        width: 93%;
        height: 25px;
    }

    .personal-details-usuario select {
        font-size: xx-small;
        width: 93%;
        height: 25px;
    }

    .personal-details-usuario select option {
        font-size: xx-small;
    }

    .personal-details-usuario .right-column label {
        font-size: xx-small;
    }

    .personal-details-usuario .right-column img {
        width: 125px;
        height: 125px;
    }

    .eyeIcon-security {
        right: 11%;
    }
}