.delete-users {
    font-size: small;
    text-align: right;
    margin-top: 0;
    margin-bottom: 13px;
    width: 90%;
}

.delete-users button {
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    background-color: #F3F7F9;
    border: none;
}

.dropdownMenu {
    position: absolute;
    z-index: 1;
}

.user-details {
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 85%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    margin-bottom: 7%;
}

.filter-userDetails {
    display: flex;
    flex-wrap: wrap;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 2%;
}

.filter-userDetails label {
    text-align: left;
    letter-spacing: 0px;
    color: #647787;
    opacity: 1;
    font-size: small;
}

.tabla-usuarios {
    width: 100%;
}

.tabla-usuarios tbody {
    font-size: 12px;
    text-align: left;
}

.tabla-usuarios th {
    text-align: left;
}

.tabla-usuarios img {
    border-radius: 50%;
    margin-right: 15px;
    width: 45px;
    height: 45px;
}

.separator td {
    background-color: #647787;
    height: 0.5px;
}

.tabla-usuario-nombre {
    display: flex;
    place-items: center;
    justify-content: left;
    margin-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tabla-header {
    text-align: inherit;
    letter-spacing: 0.2px;
    color: #647787;
    font-size: small;
    height: 40px;
}

.input-with-icon {
    width: 100%;
}

.input-with-icon input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    height: 30px;
    width: 100%;
}

.input-with-icon input:hover {
    border-color: black;
}

.input-with-icon input:focus {
    border-color: #3A93F7;
    outline: none;
}

.input-active {
    border: 1px solid #3A93F7 !important;
}

.field-container-usuarios input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    height: 30px;
    width: 100%;
}

.icono {
    font-size: 25px;
    font-family: 'Montserrat', sans-serif !important;
    color: #647787 !important;
    position: absolute;
    right: 5px;
    top: 3px;
}

.input-with-icon,
.field-container-usuarios {
    position: relative;
    display: inline-block;
}

.close-icon-name,
.close-icon-email {
    color: #3A93F7;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.boton-desplegable-usuario {
    cursor: pointer !important;
    display: flex !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #647787 !important;
    padding-left: 10px !important;
    height: 30px !important;
    width: 100% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #647787 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 10px !important;
    font-size: smaller !important;
}

.boton-desplegable-usuari {
    cursor: pointer !important;
    display: flex !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #647787 !important;
    padding-left: 10px !important;
    height: 30px !important;
    width: 100% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #647787 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 10px !important;
    font-size: 11px !important;
}

.dropdown-container-usuarios {
    overflow-y: scroll;
    height: 150px;
    margin-top: 4.5%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 280px;
}

.dropdown-container-usuarios ul {
    list-style-type: none;
}

.dropdown-container-usuarios button {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    width: 100% !important;
}

.dropdown-container-usuarios button:hover {
    background-color: #3A93F729 !important;
    border: 0.5px solid #3A93F7 !important;
    border-radius: 10px !important;
}

.dropdown-container-usuarios button:focus {
    border-color: #3A93F7 !important;
}

.boton-desplegable-usuario:hover {
    background-color: #3A93F729 !important;
    border: 0.5px solid #3A93F7 !important;
    border-radius: 10px !important;
}

.boton-desplegable-usuario:focus {
    border-color: #3A93F7 !important;
}

.custom-select-container-usuarios select {
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    height: 30px;
    width: 100%;
    padding-left: 10px;
}

.custom-select-container-usuarios select:hover {
    border-color: black;
}

.custom-select-container-usuarios select:focus {
    border-color: #3A93F7;
    outline: none;
}

.custom-select-container-usuarios {
    position: relative;
    display: inline-block;
}

.custom-select-arrow-usuarios {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.filter-users {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 91%;
}

.filter-users a {
    align-self: center;
}

.filter-users button {
    cursor: pointer;
    width: 178px;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: medium;
}

.filter-userDetails label,
.filter-userDetails .input-with-icon,
.filter-userDetails .custom-select-container-usuarios,
.field-container-usuarios {
    flex: 1 1 calc(33.33% - 10px);
    margin: 5px;
}


.input-with-icon input::placeholder {
    font-size: smaller;
    padding-left: 5px;
}

.icon-button {
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 500;

}

.menu-usuarios {
    position: relative;
}

.menu-usuarios ul {
    width: 90px;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.menu-usuarios li {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: smaller;
    font-weight: 500;
    width: 100%;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
}

.menu-usuarios ul li a {
    color: black;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
}

.menu-usuarios ul li a:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
    width: 100%;
}

.menu-usuarios ul li a.selected {
    background-color: #3A93F7;
    color: #ffffff;
}

.paginador-usuarios span {
    font-size: 12px;
    color: #647787;
    margin-top: 2%;
    margin-left: 2%;
}

.paginador-usuarios {
    display: flex;
    flex-direction: row;
}

.paginador-usuarios div {
    width: 93%;
    margin-top: 0%;
    text-align-last: right;
    padding: 1%;
}

.paginador-usuarios div button {
    cursor: pointer;
    color: #1A1A3B;
    background-color: #FFFFFF;
    font-size: 12px;
    display: inline-flex;
    border: none;
    margin: 1%;
}

.paginador-usuarios div button:disabled {
    color: #647787;
    cursor: not-allowed;
}

.paginador-usuarios .selected-page {
    background-color: #3A93F7;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 5px 10px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .filter-userDetails {
        width: 95%;
    }

}

@media screen and (max-width: 1150px) {
    .filter-userDetails {

        width: 91%;
    }

}


@media screen and (max-width: 1100px) {
    .delete-users button {
        font-size: 13px;

    }

    .filter-userDetails {
        font-size: small;
    }

    .filter-userDetails label {
        font-size: smaller;
    }

    .input-with-icon input {
        font-size: x-small;
    }

    .filter-userDetails select {
        font-size: x-small;
    }

    .filter-userDetails select option {
        font-size: x-small;
    }

    .input-with-icon input::placeholder {
        font-size: x-small;
    }

    .filter-users button {
        width: 125px;
    }

    .paginador-usuarios div {
        padding-top: 1%;
        padding-bottom: 0;
    }

    .paginador-usuarios div button {
        font-size: x-small;
    }

    .paginador-usuarios div button:disabled {
        font-size: x-small;
    }

    .tabla-header {
        font-size: x-small;
    }

    .tabla-usuarios th {
        padding-left: 2.5%;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .tabla-usuario-nombre {
        padding-left: 2.5%;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .tabla-usuarios img {
        margin-top: 5px;
        width: 35px;
        height: 35px;
    }

    .tabla-usuarios tbody {
        font-size: x-small;
    }
}


@media screen and (max-width: 1000px) {
    .input-with-icon input::placeholder {
        font-size: xx-small;
    }
}

@media screen and (max-width: 950px) {
    .input-with-icon input::placeholder {
        padding-left: 0;
        font-size: 8px;
    }

}


@media screen and (max-width: 890px) {
    .filter-users button {
        width: 100px;
        font-size: x-small;
    }
}

@media screen and (max-width: 833px) {

    .delete-users button {
        font-size: 13px;
    }

    .filter-userDetails {
        font-size: x-small;
    }

    .filter-userDetails label {
        font-size: x-small;
    }

    .input-with-icon input {
        width: 100px;
    }

    .filter-userDetails select {
        width: 100px;
    }

    .input-with-icon input::placeholder {
        padding-left: 0;
        font-size: 7px;
    }

    .paginador-usuarios div button {
        height: 30px;
        font-size: xx-small;
    }

    .paginador-usuarios div button:disabled {
        font-size: xx-small;
    }

    .tabla-header {
        font-size: xx-small;
    }

    .tabla-usuarios tbody {
        font-size: xx-small;
    }

}

@media screen and (max-width: 740px) {
    .delete-users button {
        font-size: 10px;
    }

    .filter-users button {
        height: 30px;
    }

    .filter-userDetails {
        font-size: 10px;
    }

    .filter-userDetails label {
        font-size: 10px;
    }

    .input-with-icon input {
        font-size: 11px;
        padding-left: 6px;
        padding-bottom: 2px;
        height: 25px;
    }

    .filter-userDetails select {
        font-size: 11px;
        height: 25px;
        width: 70px;
    }

    .filter-userDetails select option {
        font-size: 10px;
    }

    .input-with-icon input::placeholder {
        padding-left: 0;
        font-size: 7px;
    }

    .paginador-usuarios div button {
        font-size: 8px;
    }

    .paginador-usuarios div button:disabled {
        font-size: 8px;
    }

    .tabla-header {
        font-size: 8px;
    }

    .tabla-usuarios tbody {
        font-size: 8px;
    }

}