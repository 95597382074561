.bodyHome-user {
    display: flex;
    background-color: #F3F7F9;
    width: 100%;
}

.form-user {
    width: 100%;
}

.bodyHome-user p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #1A1A3B;
    font-size: xx-large;
    margin-top: 3rem;
    margin-left: 14vh;
    margin-bottom: 3%;
}

.userContainer-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 14vh;
}

.userContainer {
    font-family: 'Montserrat', sans-serif;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
    width: 80%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 3%;
    margin-bottom: 2%;
}

.botonPrincipal-user-container {
    display: flex;
    justify-content: center;
}

.botonPrincipal-user {
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.botonPrincipal-user:hover {
    background: #2D73EB 0% 0% no-repeat padding-box;
    border-color: #2D73EB;
}

.userContainer img {
    border-radius: 50%;
    width: 300px;
    height: 300px;
}

.userContainer input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    font-size: large;
}

.userContainer label {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: bold;
    padding-left: 7px;
}

.columns-input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 40px;
    background: #F3F7F9 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 5px #00000029;
    border-radius: 10px;
    border: none;
    opacity: 1;
    margin-top: 3%;
    margin-bottom: 6%;
}

.columns-input-empresa {
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 3px 3px 5px #00000029;
    border-radius: 10px;
    border: solid 0.5px lightgray;
    opacity: 1;
    margin-top: 3%;
    margin-bottom: 6%;
}

.columns-input-empresa::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 1px !important;
    font-size: small !important;
}

.span-empresa {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 90%;
}


.span-columns-input {
    width: 206%;
    height: 40px;
    background: #F3F7F9 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 5px #00000029;
    border-radius: 10px;
    border: none;
    opacity: 1;
    margin-top: 3%;
    margin-bottom: 6%;
}

.userContainer .column1 {
    grid-column: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userContainer .column1 h3,
.userContainer .column1 span {
    margin-top: 5px;
}

.userContainer span {
    font-weight: 500;
}

.userContainer .column1 img {
    margin-bottom: 9px;
}

.userContainer .column2 {
    grid-column: 2;
    grid-row: 1 / 3;
    height: 80%;
    padding-bottom: 10%;
}

.userContainer .column3 {
    grid-column: 3;
    grid-row: 1 / 3;
    height: 80%;
    padding-bottom: 10%;
}

@media screen and (max-width: 1900px) {
    .userContainer .column1 img {
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

}

@media screen and (max-width: 1700px) {
    .userContainer .column1 img {
        border-radius: 50%;
        width: 200px;
        height: 200px;
    }


}

@media screen and (max-width: 1700px) {
    .userContainer .column1 img {
        width: 180px;
        height: 180px;
    }

    .form-user {
        font-size: small;
    }

    .botonPrincipal-user {
        width: 197px;
        height: 40px;
        font-size: small;
    }

    .userContainer input::placeholder {
        font-size: small;
    }

}

@media screen and (max-width: 1100px) {
    .span-empresa {
        font-size: x-small;
    }

    .userContainer .column1 img {
        width: 130px;
        height: 130px;
    }

    .form-user {
        font-size: x-small;
    }

    .botonPrincipal-user {
        width: 169px;
        height: 34px;
        font-size: x-small;
    }

    .userContainer input::placeholder {
        font-size: x-small;
    }

    .bodyHome-user p {
        font-size: large;

    }
}

@media screen and (max-width: 930px) {

    .span-empresa {
        font-size: x-small;
    }

    .userContainer .column1 img {
        width: 100px;
        height: 100px;
    }

    .form-user {
        font-size: xx-small;
    }

    .botonPrincipal-user {
        width: 169px;
        height: 34px;
        font-size: xx-small;
    }

    .userContainer input::placeholder {
        font-size: xx-small;
    }

    .bodyHome-user p {
        font-size: large;
    }
}