* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containerFlip {
  position: relative;
}

.loginContainer,
.loginContainerForget {
  transition: transform 0.7s, opacity 0.7s;
  transform-style: preserve-3d;
  position: absolute;
  backface-visibility: hidden;
  opacity: 0;
}

.showFront {
  opacity: 1;
  transform: rotateY(0deg);
}

.showBack {
  opacity: 1;
  transform: rotateY(180deg);
}

.inicioContainer {
  background-color: #F3F7F9;
  display: flex;
  flex-direction: column;
}

.headerInicio {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 0.5%;
  opacity: 1;
}

.headerInicio h2 {
  font-family: 'Montserrat', sans-serif;
  color: #131357;
  font-size: 36px;
}

.bodyInicio {
  display: flex;
  flex-direction: row;
}

.bodyInicio>div {
  flex: 1;
}

.bodyInicio img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.loginContainer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 20px #00000029;
  border-radius: 15px;
  opacity: 1;
  padding: 6%;
  align-self: center;
  margin-left: 15%;
  margin-top: 5%;
  width: 65%;
  height: 75%;
}

.loginContainerForget {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 20px #00000029;
  border-radius: 15px;
  opacity: 1;
  padding: 1%;
  margin-left: 15%;
  margin-top: 20%;
  width: 75%;
  height: 50%;
  padding-left: 3.5%;
  padding-right: 4%;
}

.eyeIcon {
  font-size: 21px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  color: #3A93F7;
  cursor: pointer;
}

.eyeIcon:hover {
  color: #2D73EB;
}

.loginContainer input {
  width: 100%;
  padding: 1vh;
  border-radius: 10px;
  border: 1px solid lightgray;
}

.loginContainer input:hover {
  border-color: black;
}

.loginContainer input:focus {
  border-color: #3A93F7;
  outline: none;
}

.loginContainerForget input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-bottom: 5%;
}

.loginContainerForget input:hover {
  border-color: black;
}

.loginContainerForget input:focus {
  border-color: #3A93F7;
  outline: none;
}

.loginContainer h1 {
  font-family: 'Montserrat', sans-serif;
  color: #1A1A3B;
  font-size: 35px;
  margin-bottom: 1rem;
  margin-top: 5%;
  font-weight: bold;
}

.loginContainerForget h1 {
  font-family: 'Montserrat', sans-serif;
  color: #1A1A3B;
  font-size: 27px;
  margin-top: 5%;
}

.loginContainer p {
  font-family: 'Montserrat', sans-serif;
  margin-top: 3%;
  margin-bottom: 10%;
}

.loginContainerForget p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  margin-top: 5%;
  font-weight: 540;
  margin-bottom: 3%;
}

.inputIcon {
  position: relative;
  width: 100%;
}

.botonPrincipal-login {
  cursor: pointer;
  width: 70%;
  height: 50px;
  background: #3A93F7 0% 0% no-repeat padding-box;
  border-color: #3A93F7;
  box-shadow: 3px 3px 20px #00000029;
  border-radius: 10px;
  opacity: 1;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
}

.botonPrincipal-login:hover {
  background: #2D73EB 0% 0% no-repeat padding-box;
  border-color: #2D73EB;
}

.formlogin {
  display: flex;
  flex-direction: column;
}

.formlogin p {
  cursor: pointer;
  align-self: self-end;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #3A93F7;
  font-weight: bold;
}

.loginContainerForget h2 {
  cursor: pointer;
  align-self: self-end;
  margin-top: -15%;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  color: #3A93F7;
  position: absolute;
}

.arrowBackIcon {
  font-weight: bolder;
  margin-right: 11px;
}

.formlogin h2 {
  align-self: left;
  font-family: 'Montserrat', sans-serif;
  font-size: small;
  padding-left: 2px;
  width: 100%;
  color: red;
}

.mdError {
  margin-top: 2px;
  font-size: 21px;
  vertical-align: sub;
}

.formlogin label {
  margin-top: 1.5%;
  margin-bottom: 0.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: darkgray;
  font-weight: 580;
  display: block;
}

.formlogin input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: darkgray;
  font-weight: 550;
}

.formlogin button {
  margin-top: 3%;
  align-self: center;
}

@media screen and (max-width: 1150px) {
  .headerInicio h2 {
    font-size: 26px;
  }

  .loginContainer h1 {
    font-size: 25px;
  }

  .loginContainerForget h1 {
    font-size: 20px;
  }

  .loginContainer p {
    font-size: 13px;
  }

  .loginContainerForget p {
    font-size: smaller;
  }

  .botonPrincipal-login {
    height: 35px;
    font-size: normal;
  }

  .formlogin p {
    font-size: normal;
  }

  .loginContainerForget h2 {
    font-size: 13px;
  }

  .arrowBackIcon {
    margin-right: 8px;
  }

  .formlogin h2 {
    font-size: 11px;
  }

  .mdError {
    font-size: 16px;
  }

  .formlogin input::placeholder {
    font-size: 10px;
    padding-left: 1%;
  }

}