select::-webkit-inner-spin-button,
select::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    appearance: none;
    -moz-appearance: none;
}


select {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
}

.button-container-ajustes {
    width: 100%;
    display: flex;
    margin-top: 5vh;
    justify-content: center;
}

.bodyHome-ajustes p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #1A1A3B;
    font-size: 36px;
    margin-top: 3rem;
    margin-left: 14vh;
    margin-bottom: 3%;
}

.dropdown-container-ajustes {
    height: 62px;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 100%;
}

.dropdown-container-ajustes ul {
    list-style-type: none;
}

.dropdown-container-ajustes button {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    width: 100% !important;
}

.dropdown-container-ajustes button:hover {
    background-color: #3A93F729 !important;
    border: 0.5px solid #3A93F7 !important;
    border-radius: 10px !important;
}

.dropdown-container-ajustes button:focus {
    border-color: #3A93F7 !important;
}

.boton-desplegable-ajustes {
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    height: 30px;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.form-ajustes {
    width: 60%;
}

.selected-option {
    color: #3A93F7;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
}

.horizontal-menu {
    cursor: pointer;
    color: #647787;
    font-size: large;
    margin-left: 14vh;
    margin-bottom: 4%;
}

.horizontal-menu span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-right: 10%;
}

.button-container {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.botonSecondary-Ajustes {
    margin-left: 14vh;
    width: 35vh;
    height: 6vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-weight: bold;
    font-size: 18px;
}

.botonPrincipal-Ajustes {
    margin-left: 14vh;
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.botonPrincipal-Ajustes:hover {
    background: #2D73EB 0% 0% no-repeat padding-box;
    border-color: #2D73EB;
}

.personal-details {
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: auto;
    grid-gap: 20px;
    width: 100%;
    height: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 3%;
    padding-bottom: 2%;
}

.personal-details .left-column {
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}

.personal-details .right-column {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    place-content: center;
}

.personal-details .right-column img {
    border-radius: 50%;
    width: 280px;
    height: 280px;
    margin-bottom: 4%;
}

.personal-details .right-column label {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0px;
    color: #3A93F7;
    opacity: 1;
    font-weight: 600;
    font-size: medium;
}

.personal-details img {
    border-radius: 50%;
    width: 80%;
    height: 60%;
}

.personal-details input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details select {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details select option {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    font-size: medium;
    width: 100%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.personal-details input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-size: medium;
}

.personal-details label {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: bold;
    margin-bottom: 1.5%;
    padding-left: 7px;
}

.custom-select-container label {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 1.5%;
    padding-left: 7px;
}

.custom-select-container {
    margin-top: 2%;
    margin-bottom: 1.5%;
    position: relative;
    display: inline-block;
}

.custom-select-arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.contact-details {
    display: flex;
    flex-direction: column;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 3%;
    padding-bottom: 6.5%;
}

.contact-details input {
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    padding-left: 20px;
    padding-right: 20px;
    width: 70%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.contact-details input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-size: medium;
}

.contact-details label {
    margin-top: 2%;
    margin-bottom: 1%;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: bold;
    padding-left: 7px;
}

.security-details {
    display: flex;
    flex-direction: column;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 3%;
    padding-bottom: 6.5%;
}

.security-details input {
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    padding-left: 20px;
    width: 70%;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
}

.security-details input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-size: medium;
}

.security-details label {
    margin-top: 2%;
    margin-bottom: 1%;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: bold;
    padding-left: 7px;
}

.eyeIcon-securityA {
    font-size: 21px;
    position: absolute;
    top: 50%;
    right: 33%;
    transform: translate(0, -50%);
    color: #3A93F7;
    cursor: pointer;
}

@media screen and (max-width: 1800px) {
    .personal-details .right-column img {
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

    .personal-details {
        padding-bottom: 3.25%;
    }

}

@media screen and (max-width: 1700px) {
    .personal-details .right-column img {
        border-radius: 50%;
        width: 200px;
        height: 200px;
        margin-bottom: 1%;
    }

    .personal-details {
        padding-bottom: 5%;
    }

    .contact-details {
        padding-bottom: 3.5%;
    }

    .security-details {
        padding-bottom: 3.5%;
    }
}

@media screen and (max-width: 1300px) {
    .bodyHome-ajustes p {
        font-size: xx-large;
    }

    .horizontal-menu {
        font-size: medium;
        width: 100%;
    }

    .form-ajustes {
        font-size: small;
    }

    .personal-details input {
        font-size: small;
        height: 30px;
        padding-left: 16px;
    }

    .contact-details input {
        font-size: small;
        height: 30px;
        padding-left: 16px;
    }

    .security-details input {
        font-size: small;
        height: 30px;
        padding-left: 16px;
    }

    .personal-details select {
        font-size: small;
        height: 30px;
        padding-left: 16px;
    }

    .personal-details select option {
        font-size: small;
        height: 30px;
        padding-left: 16px;
    }

    .security-details input::placeholder {
        font-size: small;
        height: 30px;
    }

    .contact-details input::placeholder {
        font-size: small;
        height: 30px;
    }

    .personal-details input::placeholder {
        font-size: small;
    }

    .personal-details select::placeholder {
        font-size: small;
    }

    .personal-details .right-column label {
        font-size: small;
    }

    .personal-details .right-column img {
        width: 145px;
        height: 145px;
        margin-bottom: 8%;
    }

}

@media screen and (max-width: 1100px) {
    .bodyHome-ajustes p {
        font-size: x-large;
    }

    .horizontal-menu {
        font-size: small;
        width: 100%;
    }

    .form-ajustes {
        font-size: x-small;
    }

    .personal-details input {
        font-size: x-small;
        height: 30px;
        padding-left: 13px;
    }

    .contact-details input {
        font-size: x-small;
        height: 30px;
        padding-left: 13px;
    }

    .security-details input {
        font-size: x-small;
        height: 30px;
        padding-left: 13px;
    }

    .personal-details select {
        font-size: x-small;
        height: 30px;
        padding-left: 13px;
    }

    .personal-details select option {
        font-size: x-small;
        height: 30px;
        padding-left: 13px;
    }

    .security-details input::placeholder {
        font-size: x-small;
        height: 30px;
    }

    .contact-details input::placeholder {
        font-size: x-small;
        height: 30px;
    }

    .personal-details input::placeholder {
        font-size: x-small;
    }

    .personal-details select::placeholder {
        font-size: x-small;
    }

    .personal-details .right-column label {
        font-size: x-small;
    }

    .personal-details .right-column img {
        width: 125px;
        height: 125px;
        margin-bottom: 8%;
    }

}

@media screen and (max-width: 800px) {
    .bodyHome-ajustes p {
        font-size: larger;
    }

    .horizontal-menu {
        font-size: smaller;
    }

    .form-ajustes {
        font-size: xx-small;
    }

    .personal-details input {
        font-size: xx-small;
        height: 30px;
        padding-left: 13px;
    }

    .contact-details input {
        font-size: xx-small;
        height: 30px;
        padding-left: 13px;
    }

    .security-details input {
        font-size: xx-small;
        height: 30px;
        padding-left: 13px;
    }

    .personal-details select {
        font-size: xx-small;
        height: 30px;
        padding-left: 13px;
    }

    .personal-details select option {
        font-size: xx-small;
        height: 30px;
        padding-left: 13px;
    }

    .security-details input::placeholder {
        font-size: xx-small;
        height: 30px;
    }

    .contact-details input::placeholder {
        font-size: xx-small;
        height: 30px;
    }

    .personal-details input::placeholder {
        font-size: xx-small;
    }

    .personal-details select::placeholder {
        font-size: xx-small;
    }

    .personal-details .right-column label {
        font-size: xx-small;
    }

    .personal-details .right-column img {
        width: 100px;
        height: 100px;
        margin-bottom: 8%;
    }
}