.back-button-requis {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    font: normal normal 600 20px/27px Montserrat;
    color: #3A93F7;
    margin-top: 5vh;
    margin-left: 14vh;
    font-size: medium;
}

@media screen and (max-width: 1200px) {
    .button-container-proyecto {
        margin-left: 0;
    }

    .card-detalle-solicitud-titulos {
        font-size: 13.5px;
    }

    .card-detalle-solicitud-detalle {
        font-size: 12px;
    }
}

@media screen and (max-width: 1100px) {
    .titulo-container-solicitud button {
        width: 125px;
    }

    .card-informacion-solicitud {
        font-size: 13px;
    }

    .card-detalle-solicitud-span button {
        height: 35px;
        width: 125px;
        font-size: 13px;
    }

    .card-detalle-solicitud-titulos {
        font-size: 11px;
    }

    .card-detalle-solicitud-detalle {
        font-size: 11px;
    }

    .card-detalle-solicitud-total {
        font-size: 13px;
    }
}