.titulo-container-requis-solicitadas {
    display: flex;
    justify-content: space-between;
    margin-right: 14vh;
}

.titulo-container-requis-solicitadas button {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    width: 165px;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: medium;
}

.titulo-container-requis-solicitadas button:hover {
    background: #4682c7;
}

.delete-requis-solicitadas {
    font-size: small;
    text-align: right;
    margin-top: 0;
    margin-bottom: 13px;
    width: 90%;
}

.delete-requis-solicitadas button {
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    background-color: #F3F7F9;
    border: none;
}

.dropdownMenu {
    position: absolute;
    z-index: 1;
}

.user-details-requis-solicitadas {
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 85%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    margin-bottom: 7%;
}

.tabla-requis-solicitadas {
    width: 100%;
}

.tabla-requis-solicitadas tbody {
    font-size: 12px;
    text-align: left;
}

.tabla-requis-solicitadas th {
    text-align: left;
}

.tabla-requis-solicitadas td {
    position: relative;
    font-weight: 575;
    color: #1A1A3B;
    width: 25px
}

.icon-button-requis-solicitadas {
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
}

.icon-button-requis-aprobar {
    display: flex;
    color: #FFFFFF;
    background: #3A93F7 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    justify-content: center;
}

.icon-button-requis-aprobar:hover {
    background: #2D73EB 0% 0% no-repeat padding-box;
}

.icon-button-requis-rechazar {
    cursor: pointer;
    background-color: #FFFFFF;
    width: 90px;
    height: 35px;
    box-shadow: 3px 3px 5px #00000029;
    border: 1px solid #3A93F7;
    border-radius: 10px;
    opacity: 1;
    color: #3A93F7;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
}

.icon-button-requis-rechazar:hover {
    background-color: rgb(238, 236, 236);
}

.icon-button-requis-editar {
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
    opacity: 1;
    color: #3A93F7;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.menu-usuarios-requis-solicitadas {
    background: #FFFFFF;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-usuarios-requis-solicitadas ul {
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-usuarios-requis-solicitadas li {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: smaller;
    font-weight: 500;
    width: 75px;
    height: 29px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px;
    border-color: #647787;
}

.menu-usuarios-requis-solicitadas ul li a {
    color: black;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
}

.menu-usuarios-requis-solicitadas ul li a:hover {
    background-color: #3A93F729;
    border: 2px solid #3A93F7;
    border-radius: 5px;
    width: 100%;
    height: 29px;
}

.menu-usuarios-requis-solicitadas ul li a.selected {
    background-color: #3A93F7;
    color: #ffffff;
}

.menu-usuarios-requis-solicitadas .dropdownMenu-requis-solicitadas {
    right: 0;
}

@media screen and (max-width: 1100px) {
    .titulo-container-requis-solicitadas button {
        width: 125px;
    }

    .delete-requis-solicitadas button {
        font-size: 13px;
    }

    .tabla-requis-solicitadas th {
        padding-left: 2.5%;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .tabla-requis-solicitadas img {
        margin-top: 5px;
        width: 35px;
        height: 35px;
    }

    .tabla-requis-solicitadas tbody {
        font-size: x-small;
    }
}

@media screen and (max-width: 890px) {
    .titulo-container-requis-solicitadas button {
        width: 100px;
        font-size: x-small;
    }

    .delete-requis-solicitadas button {
        font-size: 13px;
    }

    .tabla-requis-solicitadas tbody {
        font-size: xx-small;
    }
}

@media screen and (max-width:740px) {
    .delete-requis-solicitadas button {
        font-size: 10px;
    }

    .tabla-requis-solicitadass tbody {
        font-size: 8px;
    }
}