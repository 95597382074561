.input-with-q-wrapper::before {
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    content: 'Q.';
    position: absolute;
    left: 10px;
    /* Ajusta este valor para alinear la Q. como desees */
    top: 55%;
    transform: translateY(-40%);
    pointer-events: none;
    /* Esto asegura que el clic en la Q. no impida enfocar el input */
}

.input-with-q-wrapper {
    position: relative;
    display: inline-block;
}

.input-with-q {
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 30px;
    height: 45px;
    width: 98%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    margin-top: 8px;
}

.input-with-q:hover {
    border-color: #3A93F7;
    border-radius: 10px;
}

.input-with-q:focus {
    border-color: #3A93F7;
}

.card-detalle-voucher {
    margin-left: 14vh;
    margin-right: 14vh;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 2%;
}

.card-detalle-voucher-span {
    display: flex;
    justify-content: space-between;
    padding-right: 4%;
    padding-top: 1%;
    padding-bottom: 4%;
}

.field-container-voucher {
    margin-bottom: 2%;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.card-informacion-solicitud-voucher {
    display: flex;
    flex-wrap: wrap;
}

.card-informacion-solicitud-voucher-input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #F3F7F9 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 5px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 5px;
    margin-top: 5px;
    border: none;
}

.card-informacion-solicitud-voucher button {
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.card-informacion-solicitud-voucher button:hover {
    background-color: #3A93F729;
    border: 0.5px solid #3A93F7;
    border-radius: 10px;
}

.card-informacion-solicitud-voucher button:focus {
    border-color: #3A93F7;
}

.button-clicked {
    border-color: #3A93F7 !important;
}

.input-active {
    border: 1px solid #3A93F7 !important;
}

.field-container-voucher input:hover {
    background-color: #3A93F729;
    border: 0.5px solid #3A93F7;
    border-radius: 10px;
}

.card-informacion-solicitud-textarea {
    width: 98%;
    height: 14vh;
    padding: 7px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 5px;
    margin-bottom: 10px;
    background: #F3F7F9 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 5px #00000029;
    border: none;
    border-radius: 10px;
    opacity: 1;
}

.field-container-voucher input:focus {
    border-color: #3A93F7;
}

.dropdown-container-proyecto {
    overflow-y: scroll;
    height: 20%;
    margin-top: 4.5%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 30%;
}

.dropdown-container-proyecto ul {
    list-style-type: none;
}

.dropdown-container-proyecto button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

.card-informacion-solicitud-voucher button:focus {
    border-color: #3A93F7;
}

.field-container-voucher input {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-top: 7px;
}

.dropdown-cheque {
    overflow-y: scroll;
    height: 27%;
    margin-top: 4.5%;
    position: absolute;
    z-index: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 20px #00000029;
    border: 1px solid #647787;
    border-radius: 10px;
    padding: 0;
    width: 30.5%;
}

.dropdown-cheque ul {
    list-style-type: none;
}

.dropdown-cheque button {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

.dropdown-cheque-nuevo-proveedor {
    display: flex;
    align-items: center;
}

.dropdown-cheque-nuevo-proveedor button {
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 20px #00000029;
    border: none;
    border-radius: 10px;
    opacity: 1;
    width: 17%;
    height: 35px;
}

.dropdown-cheque-nuevo-proveedor button:hover {
    background-color: #1f76da;
    border: #FFFFFF;
}

.dropdown-cheque-nuevo-proveedor input {
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
    height: 45px;
    width: 58%;
    border-radius: 4px;
    border: solid 0.5px #647787;
}

.monto-voucher {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}