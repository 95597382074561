.card-nuevo-proyecto {
    display: flex;
    flex-direction: column;
    margin-left: 14vh;
    font-family: 'Montserrat', sans-serif;
    width: 75%;
    height: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 20px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding-bottom: 2%;
    padding-top: 4%;
    padding-left: 7%;
    padding-right: 7%;
}

.card-nuevo-proyecto-div {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.field-proyecto {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
}

.form-fecha-proyecto {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.field-proyecto label {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    margin-bottom: 1%;
}

.card-nuevo-proyecto a {
    color: #647787;
    margin-bottom: 4.5%;
    font-size: medium;
}

.card-nuevo-proyecto form {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    font-size: medium;
    display: flex;
    justify-content: space-between;
}

.form-column-proyecto {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
}

.form-column-proyecto label {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    margin-bottom: 1%;
}

.card-nuevo-proyecto input {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    font-size: small;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787ed;
    border-radius: 10px;
    opacity: 1;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 2%;
}

.card-nuevo-proyecto input:hover {
    background-color: #3A93F729;
    border: 0.5px solid #3A93F7;
    border-radius: 10px;
}

.card-nuevo-proyecto input:focus {
    border-color: #3A93F7;
}

.card-nuevo-proyecto input::placeholder {
    color: #647787ed;
}

.custom-select-container-proyecto select {
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787ed;
    border-radius: 10px;
    opacity: 1;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    font-size: small;
}

.custom-select-container-proyecto select:hover {
    border-color: black;
}

.custom-select-container-proyecto select:focus {
    border-color: #3A93F7;
    outline: none;
}

.custom-select-container-proyecto select::placeholder {
    color: #647787ed;
}

.custom-select-container-proyecto {
    position: relative;
    display: inline-block;
}

.custom-select-arrow-proyecto {
    color: #647787ed;
    font-weight: bolder;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.button-container-proyecto {
    margin-left: -14vh;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 1%;
}

.botonSecondary-proyecto {
    letter-spacing: 1px;
    width: 35vh;
    height: 6vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-color: #F7F7F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #647787;
    font-size: 18px;
}

.botonPrincipal-proyecto {
    letter-spacing: 1px;
    cursor: pointer;
    width: 35vh;
    height: 6vh;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
    font-size: 18px;
}

.boton-desplegable {
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    padding-left: 10px;
    height: 45px;
    width: 95%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #647787;
    border-radius: 10px;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.boton-desplegable:hover {
    background-color: #3A93F729;
    border: 0.5px solid #3A93F7;
    border-radius: 10px;
}

.boton-desplegable:focus {
    border-color: #3A93F7;
}

.filter-use-p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91%;
}

.filter-use {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 91%;
}

.filter-use a {
    align-self: center;
}

.filter-use button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: black;
    border-color: #3A93F7;
    border-radius: 10px;
    opacity: 1;
    color: black;
    font-size: medium;
}

.filter-use-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    cursor: pointer;
    width: 155px;
    height: 45px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    border-color: #3A93F7;
    box-shadow: 3px 3px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
}

.button-custom-fechas {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
    font-size: small !important;
    cursor: pointer !important;
    background-color: #FFFFFF !important;
    width: 85px !important;
    height: 45px !important;
    box-shadow: 3px 3px 5px #00000029 !important;
    border: 1px solid #3A93F7 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin-bottom: 5px !important;
    margin-right: 10px !important;
    color: #3A93F7 !important;
}

@media screen and (max-width: 1200px) {
    .button-container-proyecto {
        margin-left: 0;
    }

    .botonSecondary-proyecto {
        width: 25vh;
        height: 5vh;
        font-size: 18px;
    }

    .botonPrincipal-proyecto {
        width: 25vh;
        height: 5vh;
        font-size: 18px;
    }

}

@media screen and (max-width: 1100px) {
    .card-nuevo-proyecto {
        height: 180px;
    }

    .card-nuevo-proyecto a {
        font-size: small;

    }

    .card-nuevo-proyecto form {
        font-size: small;
    }

    .card-nuevo-proyecto input {
        font-size: smaller;
    }

    .custom-select-container-proyecto select {
        font-size: smaller;
    }

    .botonSecondary-proyecto {
        width: 25vh;
        height: 5vh;
        font-size: 15px;
    }

    .botonPrincipal-proyecto {
        width: 25vh;
        height: 5vh;
        font-size: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .custom-select-container-proyecto select {
        font-size: 10px;
    }


}