.field-banco {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-right: 20px;
}

.field-tipo {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-right: 20px;
}

.field-moneda {
    font-family: 'Montserrat', sans-serif;
    color: #647787ed;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}