body.active-modal {
    overflow-y: hidden;
}

.modal {
    z-index: 1000;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: #131357D0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.modal-content-rol {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    font-size: 13px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 1%;
    border-radius: 15px;
    opacity: 1;
    height: 200px;
    width: 400px;
}


.modal-content-rol p {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: normal;
    font-size: 15px;
    padding: 5px;
}

.modal-content-rol input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: normal;
    font-size: 11px;
    padding: 10px;
    border-radius: 10px;
    height: 70px;
    width: 344px;
    border: solid 1px gray;
}

.modal-content-rol-textarea {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    color: #647787;
    font-weight: normal;
    font-size: 11px;
    padding: 10px;
    border-radius: 10px;
    height: 70px;
    width: 344px;
    border: solid 1px gray;
    resize: none;
    overflow: auto;
}


.modal-content-rol input:focus {
    border: solid 1px #3171bb;
}


.close-modal {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #3A93F7;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 7px;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border-radius: 10px;
}

.confirmation-button {
    margin-left: 10px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    background: #3A93F7 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 20px #00000029;
    border: none;
    border-radius: 10px;
    opacity: 1;
    width: 100px;
    height: 35px;
}

.confirmation-button:hover {
    background: #3171bb;
    border: solid 1px lightgray;
}

.close-button {
    margin-left: 10px;
    cursor: pointer;
    color: #3A93F7;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 20px #00000029;
    border: solid 1px #3A93F7;
    border-radius: 10px;
    opacity: 1;
    width: 100px;
    height: 35px;
}

.close-button:hover {
    background: lightgray;
    border: solid 1px gray;
}

.modal-footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}